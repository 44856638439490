import { memo } from "react";
import "./SpecialOfferBanner.scss";
import PropTypes from "prop-types";

const SpecialOfferBanner = ({ background, pictoUrl, title, titleColor }) => {
	return (
		<div
			className="special-offer-banner"
			style={{
				background: background,
				color: titleColor,
			}}
		>
			{pictoUrl && (
				<i
					className="special-offer-banner__picto"
					style={{
						maskImage: `url(${pictoUrl}`,
						WebkitMaskImage: `url(${pictoUrl}`,
					}}
				/>
			)}
			<div>{title}</div>
		</div>
	);
};

SpecialOfferBanner.propTypes = {
	title: PropTypes.string,
	pictoUrl: PropTypes.string,
	background: PropTypes.string,
	titleColor: PropTypes.string,
};

export default memo(SpecialOfferBanner);
